import React, { useEffect, useState } from 'react'
import Container from '@mui/material/Container'
import { styled as muiStyled } from '@mui/material/styles'
import styled from 'styled-components'
import Grid from '@mui/material/Grid'
import BigNumber from 'bignumber.js'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import { LineChart } from '@mui/x-charts/LineChart'
import TableRow from '@mui/material/TableRow'
import { useReliqueryContract, useMulticallContract } from '../../hooks/useContract'
import { useActiveWeb3React } from '../../hooks'
import multicall from '../../utils/multicall'
import { RELIQUERY_ADDRESS } from '../../constants'
import { ERC20_ABI } from '../../constants/abis/erc20'
import reliqueryAbi from '../../constants/abis/reliquery.json'
import FarmRow from '../../components/FarmRow'
import DepositFarmModal from '../../components/DepositFarmModal'

const StyledTableCell = muiStyled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2E2E2E',
    color: '#BEBEBE',
    border: 'none',
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: 500
  },
  [`&.${tableCellClasses.body}`]: {
    color: '#BEBEBE',
    border: 'none',
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: 500
  }
}))

const StyledTableHeaderRow = muiStyled(TableRow)(() => ({
  '& th:first-child': {
    borderRadius: '12px 0 0 12px'
  },
  '& th:last-child': {
    borderRadius: '0 12px 12px 0'
  }
}))
const TitleText = styled.h2`
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 40.32px;
  text-align: center;
  color: #fff;
`
const SubTitleText = styled.p`
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  color: #bebebe;
  text-align: left;
  margin: 0px;
`
const InfoContainer = styled.div`
  background: #181818;
  box-shadow: 0px 0px 63.5px 2px #49494940;
  padding: 20px;
  border-radius: 32px;
`
export const ContainerWithBorder = styled.div`
  position: relative;
  width: 100%;
  padding: 1px; /* Adjust if needed */
  border-radius: 32px;
  background: conic-gradient(from 180deg at 50% 50%, #ffffff 0deg, #2e2e2e 41.2deg, #2e2e2e 317.04deg, #ffffff 360deg);
`
const StyledButton = styled.button`
  background: #ffffff;
  padding: 10px;
  border-radius: 44px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  border: none;
  cursor: pointer;
`
interface UserPositions {
  relicId: any
  amount: string
  duration: any
  rewardCredit: any
  rewardDebt: any
  level: any
}
interface ReliqueryData {
  name: string
  tvl: string
  stakedAmount: string
  rewardsAmount: string
  poolToken: string
}
interface InputTokens {
  name: string
  symbol: string
  decimals: string
  address: string
  allowence: string
  balance: string
}
export default function Farms() {
  const [userData, setReliqueryData] = useState<ReliqueryData>({
    name: '',
    tvl: '',
    stakedAmount: '0',
    rewardsAmount: '0',
    poolToken: ''
  })
  const [userNfts, setUserNfts] = useState<UserPositions[]>([])
  const [showDepositModal, setShowDepositModal] = useState(false)
  const [inputTokens, setInputTokens] = useState<InputTokens[]>([])
  const reliqueryContract = useReliqueryContract()
  const multicallContract = useMulticallContract()
  const { account } = useActiveWeb3React()
  const updateUserPositions = async () => {
    try {
      const reliqueryData = { ...userData }
      let peningRewards = new BigNumber(0)
      let staked = new BigNumber(0)
      const userPositions: {
        relicId: any
        amount: string
        duration: any
        rewardCredit: any
        rewardDebt: any
        level: any
      }[] = []
      const erc20calls = [
        {
          address: userData.poolToken,
          name: 'balanceOf',
          params: [account]
        },
        {
          address: userData.poolToken,
          name: 'allowance',
          params: [account, RELIQUERY_ADDRESS]
        }
      ]
      const resp = await multicall(ERC20_ABI, erc20calls, multicallContract)
      console.log('resp user', resp)
      const inputTokenObj = {
        ...inputTokens[0],
        allowence: new BigNumber(resp[1].toString()).dividedBy(10 ** parseFloat(inputTokens[0].decimals)).toString(),
        balance: new BigNumber(resp[0].toString()).dividedBy(10 ** parseFloat(inputTokens[0].decimals)).toString()
      }
      const relicUserCalls = [
        {
          address: RELIQUERY_ADDRESS,
          name: 'pendingRewardsOfOwner',
          params: [account]
        },
        {
          address: RELIQUERY_ADDRESS,
          name: 'relicPositionsOfOwner',
          params: [account]
        }
      ]
      const relicUseresp = await multicall(reliqueryAbi, relicUserCalls, multicallContract)
      relicUseresp[0].pendingRewards.forEach((reward: { pendingReward: { toString: () => BigNumber.Value } }) => {
        peningRewards = peningRewards.plus(reward.pendingReward.toString())
      })
      relicUseresp[1].positionInfos.forEach(
        (
          position: {
            amount: BigNumber.Value
            entry: { toString: () => any }
            rewardCredit: { toString: () => any }
            rewardDebt: { toString: () => any }
            level: { toString: () => any }
          },
          index: string | number
        ) => {
          staked = staked.plus(position.amount.toString())
          userPositions.push({
            relicId: relicUseresp[1].relicIds[index].toString(),
            amount: new BigNumber(position.amount.toString()).dividedBy(10 ** 18).toString(),
            duration: position.entry.toString(),
            rewardCredit: position.rewardCredit.toString(),
            rewardDebt: position.rewardDebt.toString(),
            level: position.level.toString()
          })
        }
      )
      reliqueryData.stakedAmount = staked.dividedBy(10 ** 18).toString()
      reliqueryData.rewardsAmount = peningRewards.dividedBy(10 ** 18).toString()
      setUserNfts([...userPositions])
      setInputTokens([inputTokenObj])
      setReliqueryData({ ...reliqueryData })
    } catch (error) {
      console.log('error', error)
    }
  }
  const handleClose = () => {
    setShowDepositModal(false)
    updateUserPositions()
  }
  useEffect(() => {
    const getUserData = async () => {
      try {
        if (reliqueryContract) {
          const reliqueryData = {
            name: '',
            tvl: '0',
            stakedAmount: '0',
            rewardsAmount: '0',
            poolToken: ''
          }
          const getPoolToken = await reliqueryContract.poolToken(0)
          const relicCalls = [
            {
              address: RELIQUERY_ADDRESS,
              name: 'poolToken',
              params: [0]
            },
            {
              address: RELIQUERY_ADDRESS,
              name: 'getPoolInfo',
              params: [0]
            }
          ]
          const relicResp = await multicall(reliqueryAbi, relicCalls, multicallContract)
          console.log('resp', relicResp)
          const tvlCallResp = await multicall(
            ERC20_ABI,
            [
              {
                address: getPoolToken,
                name: 'balanceOf',
                params: [RELIQUERY_ADDRESS]
              },
              {
                address: getPoolToken,
                name: 'name'
              },
              {
                address: getPoolToken,
                name: 'symbol'
              },
              {
                address: getPoolToken,
                name: 'decimals'
              }
            ],
            multicallContract
          )
          reliqueryData.tvl = new BigNumber(tvlCallResp[0].toString()).dividedBy(10 ** 18).toString()
          reliqueryData.name = relicResp[1].pool.name
          reliqueryData.poolToken = getPoolToken
          let inputTokenObj = {
            name: tvlCallResp[1][0],
            symbol: tvlCallResp[2][0],
            decimals: tvlCallResp[3][0],
            address: getPoolToken,
            allowence: '0',
            balance: '0'
          }
          if (account) {
            let peningRewards = new BigNumber(0)
            let staked = new BigNumber(0)
            const userPositions: {
              relicId: any
              amount: string
              duration: any
              rewardCredit: any
              rewardDebt: any
              level: any
            }[] = []
            const erc20calls = [
              {
                address: getPoolToken,
                name: 'balanceOf',
                params: [account]
              },
              {
                address: getPoolToken,
                name: 'allowance',
                params: [account, RELIQUERY_ADDRESS]
              }
            ]
            const resp = await multicall(ERC20_ABI, erc20calls, multicallContract)
            console.log('resp user', resp)
            inputTokenObj = {
              ...inputTokenObj,
              allowence: new BigNumber(resp[1].toString())
                .dividedBy(10 ** parseFloat(inputTokenObj.decimals))
                .toString(),
              balance: new BigNumber(resp[0].toString()).dividedBy(10 ** parseFloat(inputTokenObj.decimals)).toString()
            }
            const relicUserCalls = [
              {
                address: RELIQUERY_ADDRESS,
                name: 'pendingRewardsOfOwner',
                params: [account]
              },
              {
                address: RELIQUERY_ADDRESS,
                name: 'relicPositionsOfOwner',
                params: [account]
              }
            ]
            const relicUseresp = await multicall(reliqueryAbi, relicUserCalls, multicallContract)
            relicUseresp[0].pendingRewards.forEach((reward: { pendingReward: { toString: () => BigNumber.Value } }) => {
              peningRewards = peningRewards.plus(reward.pendingReward.toString())
            })
            relicUseresp[1].positionInfos.forEach(
              (
                position: {
                  amount: BigNumber.Value
                  entry: { toString: () => any }
                  rewardCredit: { toString: () => any }
                  rewardDebt: { toString: () => any }
                  level: { toString: () => any }
                },
                index: string | number
              ) => {
                staked = staked.plus(position.amount.toString())
                userPositions.push({
                  relicId: relicUseresp[1].relicIds[index].toString(),
                  amount: new BigNumber(position.amount.toString()).dividedBy(10 ** 18).toString(),
                  duration: position.entry.toString(),
                  rewardCredit: position.rewardCredit.toString(),
                  rewardDebt: position.rewardDebt.toString(),
                  level: position.level.toString()
                })
              }
            )
            reliqueryData.stakedAmount = staked.dividedBy(10 ** 18).toString()
            reliqueryData.rewardsAmount = peningRewards.dividedBy(10 ** 18).toString()
            setUserNfts([...userPositions])
          }
          setInputTokens([inputTokenObj])
          setReliqueryData({ ...reliqueryData })
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    getUserData()
  }, [account, multicallContract, reliqueryContract])

  if (userNfts.length > 0) {
    userNfts.sort((a, b) => parseFloat(a.duration) - parseFloat(b.duration))
  }
  return (
    <Container maxWidth="lg">
      <DepositFarmModal open={showDepositModal} inputTokens={inputTokens} onClose={handleClose} />
      <TitleText>Liquidity Pool</TitleText>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ContainerWithBorder>
            <InfoContainer>
              <TitleText style={{ fontSize: '24px', margin: '0px' }}>Total slCHIMP</TitleText>
              <Stack spacing={3} sx={{ marginTop: '30px' }}>
                <Stack justifyContent="space-between" alignItems="center" direction="row">
                  <SubTitleText>Liquidity Pool APY %</SubTitleText>
                  <SubTitleText style={{ color: '#fff' }}>14.24%</SubTitleText>
                </Stack>
                <Stack justifyContent="space-between" alignItems="center" direction="row">
                  <SubTitleText>slCHIMP Price</SubTitleText>
                  <SubTitleText style={{ color: '#fff' }}>$1.04</SubTitleText>
                </Stack>
                <Stack justifyContent="space-between" alignItems="center" direction="row">
                  <SubTitleText>TVL</SubTitleText>
                  <SubTitleText style={{ color: '#fff' }}>{userData.tvl}</SubTitleText>
                </Stack>
                <Stack justifyContent="space-between" alignItems="center" direction="row">
                  <SubTitleText>Staked slCHIMP</SubTitleText>
                  <SubTitleText style={{ color: '#fff' }}>
                    {userData.stakedAmount} {userData.name}
                  </SubTitleText>
                </Stack>
                <Stack justifyContent="space-between" alignItems="center" direction="row">
                  <SubTitleText>Your Rewards</SubTitleText>
                  <SubTitleText style={{ color: '#fff' }}>
                    {userData.rewardsAmount} {userData.name}
                  </SubTitleText>
                </Stack>
                <StyledButton>Collect Rewards</StyledButton>
              </Stack>
            </InfoContainer>
          </ContainerWithBorder>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ContainerWithBorder>
            <InfoContainer>
              <TitleText style={{ fontSize: '24px', margin: '0px' }}>Staking Rewards</TitleText>
              <LineChart
                sx={{
                  // bottomAxis Line Styles
                  '& .MuiChartsAxis-bottom .MuiChartsAxis-line': {
                    stroke: '#C6C6C5',
                    strokeWidth: 0.4
                  },
                  // leftAxis Line Styles
                  '& .MuiChartsAxis-left .MuiChartsAxis-line': {
                    stroke: '#C6C6C5',
                    strokeWidth: 0.4
                  }
                }}
                colors={['#8B8DFC']}
                xAxis={[{ data: [0, 1, 7, 14, 30, 90, 180, 365], label: 'Days Staked', fill: '#FFFFFFBF' }]}
                series={[
                  {
                    data: [100, 120, 140, 150, 175, 200, 275, 350],
                    label: 'Power'
                  }
                ]}
                yAxis={[{ label: 'Power' }]}
                bottomAxis={{
                  disableTicks: true,
                  stroke: '#fff',
                  labelStyle: {
                    fill: '#fff'
                  },
                  tickLabelStyle: {
                    fill: '#FFFFFFBF'
                  }
                }}
                leftAxis={{
                  disableTicks: true,
                  stroke: '#fff',
                  labelStyle: {
                    fill: '#fff'
                  },
                  tickLabelStyle: {
                    fill: '#FFFFFFBF'
                  }
                }}
                width={350}
                height={300}
              />
            </InfoContainer>
          </ContainerWithBorder>
        </Grid>
      </Grid>
      <ContainerWithBorder style={{ marginTop: '30px' }}>
        <InfoContainer>
          <Stack sx={{ marginBottom: '20px' }} justifyContent="space-between" alignItems="center" direction="row">
            <TitleText style={{ fontSize: '24px', margin: '0px', textAlign: 'left' }}>My NFTs</TitleText>
            {userNfts.length > 0 && (
              <StyledButton onClick={() => setShowDepositModal(true)} style={{ padding: '9px 20px' }}>
                Create new Position
              </StyledButton>
            )}
          </Stack>
          {/* <TableContainer component={Paper}> */}
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <StyledTableHeaderRow>
                <StyledTableCell align="center">NFT</StyledTableCell>
                <StyledTableCell align="center">Amount</StyledTableCell>
                <StyledTableCell align="center">Duration</StyledTableCell>
                <StyledTableCell align="center">Level</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </StyledTableHeaderRow>
            </TableHead>
            <TableBody>
              {userNfts.map((nft, index) => (
                <FarmRow
                  key={nft.relicId}
                  {...nft}
                  inputTokens={inputTokens}
                  index={index}
                  onUpdatePosition={updateUserPositions}
                  nftToBeMergedWith={userNfts[0]}
                />
              ))}
            </TableBody>
          </Table>
          {userNfts.length === 0 && (
            <Stack spacing={3} justifyContent="center" alignItems="center" sx={{ marginTop: '30px' }}>
              <TitleText style={{ fontSize: '24px', margin: '0px', textAlign: 'left' }}>
                You don’t have any NFT’s Positions open
              </TitleText>
              <StyledButton onClick={() => setShowDepositModal(true)} style={{ padding: '9px 20px' }}>
                Create Position
              </StyledButton>
            </Stack>
          )}
          {/* </TableContainer> */}
        </InfoContainer>
      </ContainerWithBorder>
    </Container>
  )
}
