import React, { useState, useEffect } from 'react'
import { styled as muiStyled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { ArrowUpCircle } from 'react-feather'
import { useReliqueryContract } from '../../hooks/useContract'
import Dialog from '@mui/material/Dialog'
import styled from 'styled-components'
import { Stack } from '@mui/material'

const BootstrapDialog = muiStyled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    background: '#181818'
  },
  '& .MuiDialogActions-root': {
    background: '#181818'
  },
  '& .MuiDialog-paper': {
    background: '#181818',
    border: '1px solid #2E2E2E',
    boxShadow: '0px 0px 63.5px 2px #49494940',
    borderRadius: '32px',
    padding: '20px',
    minWidth: '400px'
  }
}))
const SubTitleText = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #b7b7b7;
  text-align: center;
  margin: 0px;
`
const InfoContainer = styled.div`
  background: #282828;
  padding: 20px;
  border-radius: 21px;
`

const ModalTitle = styled.p`
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.87px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #eaeaea;
  margin: 0px;
`
const StyledButton = styled.button`
  background: #ffffff;
  padding: 10px;
  border-radius: 44px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  border: none;
  cursor: pointer;
`
interface InputTokens {
  name: string
  symbol: string
  decimals: string
  address: string
  allowence: string
  balance: string
}
export interface SimpleDialogProps {
  open: boolean
  selectedValue?: string
  onClose?: () => void
  inputTokens: InputTokens[]
  relicId?: string
  nftToBeMergedWith?: UserPositions
  amount: string
  duration: string
}
interface UserPositions {
  relicId: any
  amount: string
  duration: any
  rewardCredit: any
  rewardDebt: any
  level: any
}
const TitleText = styled.h2`
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  text-align: center;
  color: #fff;
`
export default function MergeFarmModal(props: SimpleDialogProps) {
  const { onClose, open, inputTokens, relicId, nftToBeMergedWith, amount, duration } = props
  const reliqueryContract = useReliqueryContract()
  const [selectedToken, setSelectedToken] = useState<InputTokens | null>(null)
  const [txProcessing, setTxProcessing] = useState(false)
  const [txSuccess, setTxSuccess] = useState(false)
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }
  const handleMerge = async () => {
    try {
      if (reliqueryContract && nftToBeMergedWith) {
        setTxProcessing(true)
        const tx = await reliqueryContract.merge(relicId, nftToBeMergedWith?.relicId)
        await tx.wait()
        setTxProcessing(false)
        setTxSuccess(true)
      }
    } catch (error) {
      console.log('error', error)
      setTxProcessing(false)
    }
  }
  useEffect(() => {
    if (selectedToken === null && inputTokens.length > 0) {
      setSelectedToken(inputTokens[0])
    }
  }, [inputTokens, selectedToken])
  return (
    <BootstrapDialog onClose={handleClose} open={open}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <ModalTitle>Merge Positions</ModalTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      {txSuccess ? (
        <Stack spacing={4} sx={{ marginTop: '30px' }} justifyContent="center" alignItems="center">
          <ArrowUpCircle strokeWidth={0.5} size={90} color="#fff" />

          <SubTitleText style={{ textAlign: 'center', fontSize: '16px' }}>Transcation Successfull</SubTitleText>
          <StyledButton style={{ width: '100%' }} onClick={handleClose}>
            Close
          </StyledButton>
        </Stack>
      ) : (
        <Stack spacing={4} sx={{ marginTop: '30px' }}>
          <SubTitleText style={{ textAlign: 'center', fontSize: '16px' }}>
            Following Positions will be merged with eachother
          </SubTitleText>
          <Stack spacing={2}>
            <InfoContainer>
              <TitleText style={{ margin: '0px' }}>Current Position</TitleText>
              <Stack
                spacing={2}
                sx={{ marginTop: '30px' }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack justifyContent="space-between" alignItems="center">
                  <SubTitleText style={{ color: '#fff', marginBottom: '7px' }}>{relicId}</SubTitleText>
                  <SubTitleText>NFT Id</SubTitleText>
                </Stack>
                <Stack justifyContent="space-between" alignItems="center">
                  <SubTitleText style={{ color: '#fff', marginBottom: '7px' }}>{amount}</SubTitleText>
                  <SubTitleText>Amount</SubTitleText>
                </Stack>
                <Stack justifyContent="space-between" alignItems="center">
                  <SubTitleText style={{ color: '#fff', marginBottom: '7px' }}>{duration}</SubTitleText>
                  <SubTitleText>Duration</SubTitleText>
                </Stack>
              </Stack>
            </InfoContainer>
            {nftToBeMergedWith && (
              <InfoContainer>
                <TitleText style={{ margin: '0px' }}>Merge Position</TitleText>
                <Stack
                  spacing={2}
                  sx={{ marginTop: '30px' }}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack justifyContent="space-between" alignItems="center">
                    <SubTitleText style={{ color: '#fff', marginBottom: '7px' }}>
                      {nftToBeMergedWith.relicId}
                    </SubTitleText>
                    <SubTitleText>NFT Id</SubTitleText>
                  </Stack>
                  <Stack justifyContent="space-between" alignItems="center">
                    <SubTitleText style={{ color: '#fff', marginBottom: '7px' }}>
                      {nftToBeMergedWith.amount}
                    </SubTitleText>
                    <SubTitleText>Amount</SubTitleText>
                  </Stack>
                  <Stack justifyContent="space-between" alignItems="center">
                    <SubTitleText style={{ marginBottom: '7px' }}>{nftToBeMergedWith.duration}</SubTitleText>
                    <SubTitleText>Duration</SubTitleText>
                  </Stack>
                </Stack>
              </InfoContainer>
            )}
          </Stack>
          <StyledButton disabled={txProcessing} onClick={handleMerge}>
            {txProcessing ? 'Processing...' : 'Confirm Merge'}
          </StyledButton>
        </Stack>
      )}
    </BootstrapDialog>
  )
}
