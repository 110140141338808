import React, { useState } from 'react'
import { styled as muiStyled } from '@mui/material/styles'
import styled from 'styled-components'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import DepositFarmModal from '../DepositFarmModal'
import WithdrawFarmModal from '../WithdrawFarmModal'
import MergeFarmModal from '../MergeFarmModal'

const StyledTableCell = muiStyled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2E2E2E',
    color: '#BEBEBE',
    border: 'none',
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: 500
  },
  [`&.${tableCellClasses.body}`]: {
    color: '#BEBEBE',
    border: 'none',
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: 500
  }
}))
import TableRow from '@mui/material/TableRow'
import { Stack } from '@mui/material'

const StyledTableRow = muiStyled(TableRow)(() => ({
  borderBottom: '1px solid #2E2E2E',
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))
const StyledButton = styled.button`
  background: #ffffff;
  padding: 9px 22px 9px 22px;
  border-radius: 44px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  border: none;
  cursor: pointer;
`
interface InputTokens {
  name: string
  symbol: string
  decimals: string
  address: string
  allowence: string
  balance: string
}
interface UserPositions {
  relicId: any
  amount: string
  duration: any
  rewardCredit: any
  rewardDebt: any
  level: any
}
export default function FarmRow({
  relicId,
  amount,
  duration,
  inputTokens,
  index,
  level,
  onUpdatePosition,
  nftToBeMergedWith
}: {
  relicId: string
  amount: string
  duration: number
  inputTokens: InputTokens[]
  index: number
  level: string
  onUpdatePosition?: () => void
  nftToBeMergedWith?: UserPositions
}) {
  const [showDepositModal, setShowDepositModal] = useState(false)
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)
  const [showMergeModal, setShowMergeModal] = useState(false)
  const timePassed = (timestampInSeconds: number) => {
    const now = new Date()
    const past = new Date(timestampInSeconds * 1000)

    const diffMilliseconds = now.getTime() - past.getTime()
    const diffSeconds = diffMilliseconds / 1000
    const diffMinutes = diffSeconds / 60
    const diffHours = diffMinutes / 60
    const diffDays = diffHours / 24
    const diffMonths = diffDays / 30.436875 // Average days per month
    const diffYears = diffDays / 365.2425 // Average days per year

    if (diffYears >= 1) {
      return `${Math.floor(diffYears)} year${Math.floor(diffYears) > 1 ? 's' : ''}`
    } else if (diffMonths >= 1) {
      return `${Math.floor(diffMonths)} month${Math.floor(diffMonths) > 1 ? 's' : ''}`
    } else if (diffDays >= 1) {
      return `${Math.floor(diffDays)} day${Math.floor(diffDays) > 1 ? 's' : ''}`
    } else if (diffHours >= 1) {
      return `${Math.floor(diffHours)} hour${Math.floor(diffHours) > 1 ? 's' : ''}`
    } else {
      return 'less than an hour'
    }
  }
  const handleClose = () => {
    setShowDepositModal(false)
    if (onUpdatePosition) {
      onUpdatePosition()
    }
  }
  const handleCloseWithdraw = () => {
    setShowWithdrawModal(false)
    if (onUpdatePosition) {
      onUpdatePosition()
    }
  }
  const handleCloseMerge = () => {
    setShowMergeModal(false)
    if (onUpdatePosition) {
      onUpdatePosition()
    }
  }
  return (
    <StyledTableRow key={relicId}>
      <DepositFarmModal
        open={showDepositModal}
        inputTokens={inputTokens}
        onClose={handleClose}
        isExistingPosition
        relicId={relicId}
      />
      <WithdrawFarmModal
        open={showWithdrawModal}
        inputTokens={inputTokens}
        onClose={handleCloseWithdraw}
        relicId={relicId}
      />
      <MergeFarmModal
        open={showMergeModal}
        inputTokens={inputTokens}
        onClose={handleCloseMerge}
        relicId={relicId}
        nftToBeMergedWith={
          nftToBeMergedWith ? { ...nftToBeMergedWith, duration: timePassed(nftToBeMergedWith.duration) } : undefined
        }
        amount={amount}
        duration={timePassed(duration)}
      />
      <StyledTableCell align="center">{relicId}</StyledTableCell>
      <StyledTableCell align="center">{amount}</StyledTableCell>
      <StyledTableCell align="center">{timePassed(duration)}</StyledTableCell>
      <StyledTableCell align="center">{level}</StyledTableCell>
      <StyledTableCell align="center">
        <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
          <StyledButton onClick={() => setShowDepositModal(true)}>Stake</StyledButton>
          <StyledButton onClick={() => setShowWithdrawModal(true)}>Unstake</StyledButton>
          {index > 0 && <StyledButton onClick={() => setShowMergeModal(true)}>Merge</StyledButton>}
        </Stack>
      </StyledTableCell>
    </StyledTableRow>
  )
}
