/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
// @ts-nocheck
import { CurrencyAmount, JSBI, Token, Trade } from '@auroblocks/uniswap-sdk'
import styled from 'styled-components'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import ReactGA from 'react-ga'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary, ButtonConfirmed } from '../../components/Button'
import Card, { GreyCard } from '../../components/Card'
import Column, { AutoColumn } from '../../components/Column'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween } from '../../components/Row'
import Settings from '../../components/Settings'
import poweredByTen from '../../assets/images/poweredByTen.png'
// import eyeOff from '../../assets/images/eye-off.png'
// import linkIcon from '../../assets/images/link-icon.png'
import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown'
import { DefaultVersionLink } from '../../components/swap/BetterTradeLink'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import TradePrice from '../../components/swap/TradePrice'
import TokenWarningModal from '../../components/TokenWarningModal'
import ProgressSteps from '../../components/ProgressSteps'

import {
  BETTER_TRADE_LINK_THRESHOLD,
  INITIAL_ALLOWED_SLIPPAGE,
  CHIMP_API_URL
  // TEN_TESTNET_STEPS
} from '../../constants'
import { getTradeVersion, isTradeBetter } from '../../data/V1'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { DEFAULT_VERSION, Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserSlippageTolerance } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import { ClickableText } from '../Pool/styleds'
import Loader from '../../components/Loader'
import './index.css'
import { Stack } from '@mui/material'
import lightarrow from '../../assets/images/lightarrow.png'

const SwapComponent = styled.div`
  max-width: 100%;
  background: #181818;
  border: 1px solid #2e2e2e;
  border-radius: 32px;
  padding: 20px;
  box-shadow: 0px 0px 63.5px 2px #49494940, 0px 4px 42.9px -8px #ffffff40;
`

const Container = styled.div`
  position: relative;
  max-width: 550px;
  padding: 1px; /* Adjust if needed */
  border-radius: 32px;
  background: conic-gradient(from 180deg at 50% 50%, #ffffff 0deg, #2e2e2e 41.2deg, #2e2e2e 317.04deg, #ffffff 360deg);
`

export default function Swap() {
  const loadedUrlParams = useDefaultsFromURLSearch()

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  const { account } = useActiveWeb3React()
  // @ts-ignore
  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const toggleSettings = useToggleSettingsMenu()
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError
  } = useDerivedSwapInfo()
  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)
  const toggledVersion = useToggledVersion()
  const tradesByVersion = {
    [Version.v1]: v1Trade,
    [Version.v2]: v2Trade
  }
  const trade = showWrap ? undefined : tradesByVersion[toggledVersion]
  const defaultTrade = showWrap ? undefined : tradesByVersion[DEFAULT_VERSION]
  const betterTradeLinkVersion: Version | undefined =
    toggledVersion === Version.v2 && isTradeBetter(v2Trade, v1Trade, BETTER_TRADE_LINK_THRESHOLD)
      ? Version.v2
      : undefined
      ? Version.v2
      : undefined

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
      }
  console.log('parsedAmount', parsedAmount)
  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }
  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, allowedSlippage, recipient)

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then(async hash => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })
        console.log('hash', hash, trade, formattedAmounts)
        const addSwapTx = {
          tokenin: trade?.inputAmount.currency.symbol === 'ETH' ? 'ETH' : trade?.inputAmount.token.address,
          tokenout: trade?.outputAmount.currency.symbol === 'ETH' ? 'ETH' : trade?.outputAmount.token.address,
          amount: formattedAmounts[Field.INPUT],
          hash: hash,
          address: account
        }
        const myHeaders = new Headers()
        myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
        const options = {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(addSwapTx)
        }
        const addSwapEvent = await fetch(`${CHIMP_API_URL}/chimpten/addSwapTx`, options)
        await addSwapEvent.json()
        ReactGA.event({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
              ? 'Swap w/o Send + recipient'
              : 'Swap w/ Send',
          label: [
            trade?.inputAmount?.currency?.symbol,
            trade?.outputAmount?.currency?.symbol,
            getTradeVersion(trade)
          ].join('/')
        })
      })
      .catch(error => {
        console.log('error', error)
        console.trace('error trace', error)
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined
        })
      })
  }, [tradeToConfirm, account, priceImpactWithoutFee, recipient, recipientAddress, showConfirm, swapCallback, trade])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    inputCurrency => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
    onCurrencySelection
  ])

  return (
    <>
      <TokenWarningModal
        isOpen={urlLoadedTokens.length > 0 && !dismissTokenWarning}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      />
      <AppBody>
        <div className="swap-container">
          {/* <div className="info-conatiner">
            <p style={{ fontSize: '20px', fontWeight: '800' }}>Getting started</p>
            {TEN_TESTNET_STEPS.map((step, index) => (
              <div className="row-container" key={step.link}>
                <div className="number-conatainer">{index + 1}</div>
                <p>{step.title} </p>
                <a className="link-button" href={step.link} target="_blank" rel="noreferrer">
                  {step.buttonText}
                  <img src={linkIcon} alt="link" width="20px" style={{ marginLeft: '5px' }} />
                </a>
              </div>
            ))}
          </div> */}
          <h2 className="title-text">Confidential Swap</h2>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginBottom: '30px' }}>
            <h6 className="sub-title-text">Powered by</h6>
            <img src={poweredByTen} alt="Ten" width="80px" />
          </Stack>
          <Container>
            <SwapComponent>
              <SwapPoolTabs active={'swap'} />
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <div className="swap-card-text">Swap</div>
                <Settings />
              </div>
              <Wrapper id="swap-page">
                <ConfirmSwapModal
                  isOpen={showConfirm}
                  trade={trade}
                  originalTrade={tradeToConfirm}
                  onAcceptChanges={handleAcceptChanges}
                  attemptingTxn={attemptingTxn}
                  txHash={txHash}
                  recipient={recipient}
                  allowedSlippage={allowedSlippage}
                  onConfirm={handleSwap}
                  swapErrorMessage={swapErrorMessage}
                  onDismiss={handleConfirmDismiss}
                />

                <div className="swap-section" style={{ position: 'relative' }}>
                  <div style={{ width: '50%' }}>
                    <CurrencyInputPanel
                      label={independentField === Field.OUTPUT && !showWrap && trade ? 'From (estimated)' : 'From'}
                      value={formattedAmounts[Field.INPUT]}
                      showMaxButton={!atMaxAmountInput}
                      currency={currencies[Field.INPUT]}
                      onUserInput={handleTypeInput}
                      onMax={handleMaxInput}
                      onCurrencySelect={handleInputSelect}
                      otherCurrency={currencies[Field.OUTPUT]}
                      id="swap-currency-input"
                    />
                  </div>
                  <AutoColumn justify="space-between">
                    <AutoRow
                      onClick={() => {
                        setApprovalSubmitted(false) // reset 2 step UI for approvals
                        onSwitchTokens()
                      }}
                      justify={isExpertMode ? 'space-between' : 'center'}
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        zIndex: '6',
                        top: '0px',
                        bottom: '0px',
                        borderRadius: '50%',
                        margin: 'auto',
                        width: '40px',
                        height: '40px',
                        right: '0px',
                        left: '0px',
                        background: 'linear-gradient(180deg, #282828 0%, #181818 100%)',
                        border: '1px solid #2E2E2E'
                      }}
                    >
                      <ArrowWrapper clickable style={{ display: 'flex' }}>
                        <SwapHorizIcon
                          fontSize="16"
                          color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? theme.primary1 : theme.text2}
                        />
                      </ArrowWrapper>
                      {recipient === null && !showWrap && isExpertMode ? (
                        <LinkStyledButton id="add-recipient-button" onClick={() => onChangeRecipient('')}>
                          + Add a send (optional)
                        </LinkStyledButton>
                      ) : null}
                    </AutoRow>
                  </AutoColumn>
                  <div style={{ width: '50%' }}>
                    <CurrencyInputPanel
                      value={formattedAmounts[Field.OUTPUT]}
                      onUserInput={handleTypeOutput}
                      label={independentField === Field.INPUT && !showWrap && trade ? 'To (estimated)' : 'To'}
                      showMaxButton={false}
                      currency={currencies[Field.OUTPUT]}
                      onCurrencySelect={handleOutputSelect}
                      otherCurrency={currencies[Field.INPUT]}
                      alignRight
                      id="swap-currency-output"
                    />
                  </div>
                </div>
                <AutoColumn gap={'sm'} style={{ position: 'relative' }}>
                  {recipient && (
                    <>
                      <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                        <ArrowWrapper clickable={false}>
                          <SwapHorizIcon
                            fontSize="16"
                            onClick={() => {
                              setApprovalSubmitted(false) // reset 2 step UI for approvals
                              onSwitchTokens()
                            }}
                            color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? theme.primary1 : theme.text2}
                          />
                        </ArrowWrapper>
                        <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                          - Remove send
                        </LinkStyledButton>
                      </AutoRow>
                      <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                    </>
                  )}
                  {!showWrap ? null : (
                    <Card padding={'.25rem .75rem 0 .75rem'} borderRadius={'20px'}>
                      <AutoColumn gap="4px">
                        {Boolean(trade) && (
                          <RowBetween align="center">
                            <Text fontWeight={500} fontSize={14} color={theme.text2}>
                              Price
                            </Text>
                            <TradePrice
                              price={trade?.executionPrice}
                              showInverted={showInverted}
                              setShowInverted={setShowInverted}
                            />
                          </RowBetween>
                        )}
                        {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                          <RowBetween align="center">
                            <ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
                              Slippage Tolerance
                            </ClickableText>
                            <ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
                              {allowedSlippage / 100}%
                            </ClickableText>
                          </RowBetween>
                        )}
                      </AutoColumn>
                    </Card>
                  )}
                  <BottomGrouping>
                    {!account ? (
                      <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
                    ) : showWrap ? (
                      <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
                        {wrapInputError ??
                          (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
                      </ButtonPrimary>
                    ) : noRoute && userHasSpecifiedInputOutput ? (
                      <GreyCard style={{ textAlign: 'center' }}>
                        <TYPE.main mb="4px">Insufficient liquidity for this trade.</TYPE.main>
                      </GreyCard>
                    ) : showApproveFlow ? (
                      <RowBetween>
                        <ButtonConfirmed
                          onClick={approveCallback}
                          disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                          width="48%"
                          altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                          confirmed={approval === ApprovalState.APPROVED}
                        >
                          {approval === ApprovalState.PENDING ? (
                            <AutoRow gap="6px" justify="center">
                              Approving <Loader stroke="white" />
                            </AutoRow>
                          ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                            'Approved'
                          ) : (
                            'Approve ' + currencies[Field.INPUT]?.symbol
                          )}
                        </ButtonConfirmed>
                        <ButtonError
                          onClick={() => {
                            if (isExpertMode) {
                              handleSwap()
                            } else {
                              setSwapState({
                                tradeToConfirm: trade,
                                attemptingTxn: false,
                                swapErrorMessage: undefined,
                                showConfirm: true,
                                txHash: undefined
                              })
                            }
                          }}
                          width="48%"
                          id="swap-button"
                          style={{
                            background:
                              !isValid ||
                              approval !== ApprovalState.APPROVED ||
                              (priceImpactSeverity > 3 && !isExpertMode)
                                ? ' #FFFFFF12'
                                : '#ffffff'
                          }}
                          disabled={
                            !isValid ||
                            approval !== ApprovalState.APPROVED ||
                            (priceImpactSeverity > 3 && !isExpertMode)
                          }
                          error={isValid && priceImpactSeverity > 2}
                        >
                          <Text fontSize={16} fontWeight={500}>
                            {priceImpactSeverity > 3 && !isExpertMode
                              ? `Price Impact High`
                              : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                          </Text>
                        </ButtonError>
                      </RowBetween>
                    ) : (
                      <ButtonError
                        onClick={() => {
                          if (isExpertMode) {
                            handleSwap()
                          } else {
                            setSwapState({
                              tradeToConfirm: trade,
                              attemptingTxn: false,
                              swapErrorMessage: undefined,
                              showConfirm: true,
                              txHash: undefined
                            })
                          }
                        }}
                        id="swap-button"
                        style={{
                          background:
                            !isValid ||
                            approval !== ApprovalState.APPROVED ||
                            (priceImpactSeverity > 3 && !isExpertMode)
                              ? ' #FFFFFF12'
                              : '#ffffff'
                        }}
                        disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
                        error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
                      >
                        <Text fontSize={20} fontWeight={500}>
                          {swapInputError
                            ? swapInputError
                            : priceImpactSeverity > 3 && !isExpertMode
                            ? `Price Impact Too High`
                            : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                        </Text>
                      </ButtonError>
                    )}
                    {showApproveFlow && (
                      <Column style={{ marginTop: '1rem' }}>
                        <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />
                      </Column>
                    )}
                    {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                    {betterTradeLinkVersion ? (
                      <DefaultVersionLink />
                    ) : toggledVersion !== DEFAULT_VERSION && defaultTrade ? (
                      <DefaultVersionLink />
                    ) : null}
                  </BottomGrouping>
                </AutoColumn>
              </Wrapper>
              {trade && <AdvancedSwapDetailsDropdown trade={trade} />}
              {/* <div className="encryption-div">
              <img src={eyeOff} alt="chimp privacy" width="20px" style={{ marginRight: '10px' }} />
              Your transactions are End-to-End Encrypted
            </div> */}
            </SwapComponent>
          </Container>
        </div>
        <Stack justifyContent="center" alignItems="center" sx={{ marginTop: '30px' }}>
          <div
            className="galaxe-container"
            onClick={() => window.open('https://app.galxe.com/quest/chimpexchange/GCw4mtzTjB', '_blank')}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <div className="arrow-container">
                <img src={lightarrow} alt="Galxe Chimp" width="45px" height="45px" />
              </div>
              <div>
                <p className="sub-title-text" style={{ textAlign: 'left', marginBottom: '5px' }}>
                  New Galxe Campaign is Live{' '}
                </p>
                <p className="sub-title-text" style={{ fontSize: '14px', textAlign: 'left', marginTop: '0px' }}>
                  Go to Galxe & complete the challenges to earn XP.{' '}
                </p>
              </div>
            </Stack>
          </div>
        </Stack>
      </AppBody>
    </>
  )
}
