import * as React from 'react'
import { styled } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import { useHistory, useLocation } from 'react-router-dom'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import { Stack } from '@mui/material'
import { ROUTES } from '../../constants/index'

const CustomIconButton = styled(IconButton)({
  height: '44px',
  width: '44px',
  justifyContent: 'center',
  padding: '10px',
  borderRadius: '12px'
})
export default function BottomTabs() {
  const history = useHistory()
  const location = useLocation()
  const navigateToPath = (path: string, isLive: boolean) => {
    if (isLive) {
      history.push(path)
    }
  }
  return (
    <AppBar
      position="fixed"
      sx={{
        minHeight: '82px',
        top: 'auto',
        bottom: 0,
        background: 'linear-gradient(149.65deg,#0f0f0f -2.81%,#0a0b49 75.27%)',
        borderRadius: '22px 22px 0px 0px'
      }}
    >
      <Toolbar sx={{ width: '100%', minHeight: '82px' }}>
        <Stack spacing={2} alignItems="center" direction="row" sx={{ width: '100%' }} justifyContent="space-evenly">
          {ROUTES.map(route => (
            <CustomIconButton
              onClick={() => navigateToPath(route.path, route.isLive)}
              sx={{
                background: route.path.includes(location.pathname) ? '#FFFFFF1F' : 'transparent'
              }}
              key={route.path}
              color="inherit"
            >
              <img src={route.path.includes(location.pathname) ? route.iconSelected : route.icon} width="20px" />
            </CustomIconButton>
          ))}
        </Stack>
      </Toolbar>
    </AppBar>
  )
}
