// @ts-nocheck
import { Interface } from '@ethersproject/abi'
import { BytesLike } from 'ethers'

interface Call {
  address: string // Address of the contract
  name: string // Function name on the contract (exemple: balanceOf)
  params?: any[] // Function params
}

const multicall = async (abi: any[], calls: Call[], multicallContract: any) => {
  const multi = multicallContract
  const itf = new Interface(abi)

  const calldata = calls.map(call => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
  const { returnData } = await multi.aggregate(calldata)
  const res = returnData.map((call: BytesLike, i: string | number) => itf.decodeFunctionResult(calls[i].name, call))

  return res
}

export default multicall
