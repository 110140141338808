import React from 'react'
import styled from 'styled-components'
import Grid from '@mui/material/Grid'
import { ExternalLink } from '../../theme'

const InfoCard = styled.button<{ active?: boolean }>`
  background-color: #282828;
  padding: 1rem;
  outline: none;
  padding: 20px 10px;
  width: 120px;
  height: 120px;
  border: 1px solid;
  padding: 10px 20px;
  border-radius: 20px;
  &:focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.primary1};
  }
`

const OptionCard = styled(InfoCard as any)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const OptionCardClickable = styled(OptionCard as any)<{ clickable?: boolean }>`
  margin-top: 0;
  &:hover {
    cursor: ${({ clickable }) => (clickable ? 'pointer' : '')};
    border: ${({ clickable, theme }) => (clickable ? `1px solid ${theme.primary1}` : ``)};
  }
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`

const GreenCircle = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  justify-content: center;
  align-items: center;

  &:first-child {
    height: 8px;
    width: 8px;
    margin-right: 8px;
    background-color: ${({ theme }) => theme.green1};
    border-radius: 50%;
  }
`

const CircleWrapper = styled.div`
  color: ${({ theme }) => theme.green1};
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeaderText = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  color: ${props => (props.color === 'blue' ? ({ theme }) => theme.primary1 : ({ theme }) => theme.text1)};
  font-size: 12px;
  font-weight: 500;
`

const SubHeader = styled.div`
  color: ${({ theme }) => theme.text1};
  margin-top: 10px;
  font-size: 12px;
`

const IconWrapper = styled.div<{ size?: number | null }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '24px')};
    width: ${({ size }) => (size ? size + 'px' : '24px')};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`

export default function Option({
  link = null,
  clickable = true,
  // size,
  onClick = null,
  color,
  header,
  subheader = null,
  icon,
  active = false,
  id
}: {
  link?: string | null
  clickable?: boolean
  size?: number | null
  onClick?: null | (() => void)
  color: string
  header: React.ReactNode
  subheader: React.ReactNode | null
  icon: string
  active?: boolean
  id: string
}) {
  const content = (
    <OptionCardClickable
      style={{ width: subheader ? 'fit-content' : '100%' }}
      id={id}
      onClick={onClick}
      clickable={clickable && !active}
      active={active}
    >
      <IconWrapper size={50}>
        <img src={icon} alt={'Icon'} />
      </IconWrapper>
      <HeaderText color={color}>
        {active ? (
          <CircleWrapper>
            <GreenCircle>
              <div />
            </GreenCircle>
          </CircleWrapper>
        ) : (
          ''
        )}
        {header}
      </HeaderText>
      {subheader && <SubHeader>{subheader}</SubHeader>}
    </OptionCardClickable>
  )
  if (link) {
    return (
      <Grid item xs={4} sm={4} md={4} lg={4}>
        <ExternalLink href={link}>{content}</ExternalLink>
      </Grid>
    )
  }

  return (
    <Grid item xs={4} sm={4} md={4} lg={4}>
      {content}
    </Grid>
  )
}
